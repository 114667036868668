import { Node, Plugin } from 'tiptap'

import store from '@/store'
import {
  EVENT_WIKI_EDIT_TOKEN,
} from '../../../../mutations-types'

export default class Tokenedit extends Node {

  get name() {
    return 'tokenedit'
  }

  get defaultOptions() {
    return {
    }
  }

  get plugins() {
    return [
      new Plugin({
        props: {
          handleClick(view, pos, event) {
              if (event.target.localName === "span" && event.target.className === "token") {
                store.dispatch('wiki/' + EVENT_WIKI_EDIT_TOKEN, event.target.getAttribute("data-token-key"))
              }
          },
        },
      }),
    ]
  }

  get schema() {
    return {
    }
  }
  commands({ schema }) {
    return
  }

}
