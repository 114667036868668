import { Node } from 'tiptap'
import { replaceText } from 'tiptap-commands'
import { SuggestionsPlugin, Mention as MentionOriginal } from 'tiptap-extensions'

export default class Hashtag extends MentionOriginal {

  get name() {
    return 'hashtag'
  }

  get defaultOptions() {
    return {
      matcher: {
        char: '#',
        allowSpaces: false,
        startOfLine: false,
      },
      mentionClass: 'hashtag',
      suggestionClass: 'hashtag-suggestion',
    }
  }

  get schema() {
    return {
      attrs: {
        id: {},
        label: {},
      },
      group: 'inline',
      inline: true,
      selectable: false,
      atom: true,
      toDOM: node => [
        'span',
        {
          class: this.options.mentionClass,
          'data-hashtag-id': node.attrs.label,
          'data-char': this.options.matcher.char,
        },
        `${node.attrs.label}`,
      ],
      parseDOM: [
        {
          tag: 'span[data-hashtag-id]',
          getAttrs: dom => {
            const id = dom.getAttribute('data-hashtag-id')
            const label = dom.innerText.split(this.options.matcher.char).join('')
            return { id, label }
          },
        },
      ],
    }
  }

}
