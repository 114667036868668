import { Node, Plugin } from 'tiptap'
import { replaceText } from 'tiptap-commands'
import { SuggestionsPlugin, Mention as MentionOriginal } from 'tiptap-extensions'

export default class Token extends MentionOriginal {

  get name() {
    return 'token'
  }

  get defaultOptions() {
    return {
      matcher: {
        char: '{{',
        charAfter: '}}',
        allowSpaces: false,
        startOfLine: false,
      },
      mentionClass: 'token',
      suggestionClass: 'token-suggestion',
    }
  }

  get schema() {
    return {
      attrs: {
        id: {},
        key: {},
        label: {},
      },
      group: 'inline',
      inline: true,
      selectable: false,
      atom: true,
      toDOM: node => [
        'span',
        {
          class: this.options.mentionClass,
          'data-token-id': node.attrs.key,
          'data-token-key': node.attrs.key,
          'data-char': this.options.matcher.char,
          'data-char-after': this.options.matcher.charAfter,
          'data-token-name': node.attrs.key,
        },
        `${node.attrs.label}`,
      ],
      parseDOM: [
        {
          tag: 'span[data-token-id]',
          getAttrs: dom => {
            const id = dom.getAttribute('data-token-key')
            const key = dom.getAttribute('data-token-key')
            const label = dom.innerText.split(this.options.matcher.char).join('')
            return { id, key, label }
          },
        },
      ],
    }
  }

  commands({ schema }) {
    return (attrs) => replaceText(null, schema.nodes[this.name], attrs)
  }

}
