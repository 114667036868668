import { Node } from 'tiptap'
import { replaceText } from 'tiptap-commands'
import { SuggestionsPlugin, Mention as MentionOriginal } from 'tiptap-extensions'

export default class Emoji extends MentionOriginal {

  get name() {
    return 'emoji'
  }

  get defaultOptions() {
    return {
      matcher: {
        char: ':',
        allowSpaces: false,
        startOfLine: false,
      },
      mentionClass: 'emoji',
      suggestionClass: 'emoji-suggestion',
    }
  }

  get schema() {
    return {
      attrs: {
        id: {},
        code: {},
        label: {},
      },
      group: 'inline',
      inline: true,
      selectable: false,
      atom: true,
      toDOM: node => [
        'span',
        {
          class: this.options.mentionClass,
        },
        `${node.attrs.code}`,
      ],
    }
  }

}
