import { Mark } from 'tiptap'
import { insertText, markInputRule, markPasteRule } from 'tiptap-commands'

export default class TokenMark extends Mark {

  get name() {
    return 'tokenmark'
  }

  get schema() {
    return {
      attrs: {
        text: {
          default: null,
        },
      },
    }
  }

  commands({ type }) {
    return (attrs) => {
        let text = attrs.text.replace(/\{/g, '').replace(/\}/g, '')
        return insertText('{{'+ text +'}}')
    }
  }

  // inputRules({ type }) {
  //   return [
  //     markInputRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)$/, type),
  //   ]
  // }

  // pasteRules({ type }) {
  //   return [
  //     markPasteRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)/g, type),
  //   ]
  // }

}
