import { Mark } from 'tiptap'
import { toggleMark, markInputRule, markPasteRule } from 'tiptap-commands'

export default class CenterMark extends Mark {

  get name() {
    return 'centermark'
  }

  get schema() {
    return {
      attrs: {
        textAlign: {
          default: 'left',
        },
      },
      content: 'inline*',
      defining: true,
      draggable: false,
      parseDOM: [{
        tag: `span[style="text-align:center; display:block;"]`,
        attrs: { textAlign: 'center' },
      }],
      toDOM: (node) => ['span', { style: `text-align:center; display:block;` }, 0],
    }
  }

  commands({ type }) {
    return (attrs) => toggleMark(type, attrs)
  }

  // inputRules({ type }) {
  //   return [
  //     markInputRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)$/, type),
  //   ]
  // }

  // pasteRules({ type }) {
  //   return [
  //     markPasteRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)/g, type),
  //   ]
  // }

}
